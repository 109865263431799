import { createAction, props } from '@ngrx/store'
import { ReviewModel, ReviewStructuredModel, ReviewNonStructuredModel, RequestModel, WidgetModel, LocationModel } from '../../models/index'

export const findLocationById = createAction(
    '[Widgets Page] Find Location By Id',
    props<{ request: RequestModel }>()
)

export const findLocationByIdSuccess = createAction(
    '[Widgets Page] Find Location By Id Success',
    props<{ location: LocationModel }>()
)

export const filterReviewsByLocation = createAction(
    '[Widgets Page] Filter Reviews By Location',
    props<{ request: RequestModel }>()
)

export const getWidgetConfig = createAction(
    '[Widgets Page] Get Widget Config',
    props<{ request: RequestModel }>()
)

export const getWidgetConfigSuccess = createAction(
    '[Widgets Page] Get Widget Config Success',
    props<{ widget: WidgetModel }>()
)

export const getReviewStructuredInfo = createAction(
    '[Widgets Page] Get Review Structured Info',
    props<{ request: RequestModel }>()
)

export const getReviewStructuredInfoSuccess = createAction(
    '[Widgets Page] Get Review Structured Info Success',
    props<{ data: ReviewModel [], total: number }>()
)

export const getPagesId = createAction(
    '[Widgets Page] Get Pages Id',
    props<{ request: RequestModel }>()
)

export const getPagesIdSuccess = createAction(
    '[Widgets Page] Get Pages Id Success',
    props<{ data: {page: number, startKey: string, startDatePosted: number}[] }>()
)

export const getReviewNonStructuredInfo = createAction(
    '[Widgets Page] Get Review Non Structured Info',
    props<{ request: RequestModel }>()
)

export const getReviewNonStructuredInfoSuccess = createAction(
    '[Widgets Page] Get Review Non Structured Info Success',
    props<{ data: ReviewModel [], total: number }>()
)

export const getReviewsByUrl = createAction(
    '[Widgets Page] Get Reviews By Url',
    props<{ request: RequestModel }>()
)
    
export const searchWidgetReviewsSuccess = createAction(
    '[Widgets Page] Search Reviews Success',
    props<{ total: number, data: ReviewModel[], widget: WidgetModel }>()
)

export const getLoctionReviewsCount = createAction(
    '[Widgets Page] Get Location Reviews Count',
    props<{ request: RequestModel }>()
)

export const getLoctionReviewsCountSuccess = createAction(
    '[Widgets Page] Get Location Reviews Count Success',
    props<{ reviewsCount: number }>()
)

export const getLoctionOverallRating = createAction(
    '[Widgets Page] Get Location Overall Rating',
    props<{ request: RequestModel }>()
)

export const getLoctionOverallRatingSuccess = createAction(
    '[Widgets Page] Get Location Overall Rating Success',
    props<{ rating: number }>()
)

export const getLoctionStarsCount = createAction(
    '[Widgets Page] Get Location Stars Count',
    props<{ request: RequestModel }>()
)

export const getLoctionStarsCountSuccess = createAction(
    '[Widgets Page] Get Location Stars Count Success',
    props<{ starsCount: number[] }>()
)

export const getLoctionGenericLanding = createAction(
    '[Widgets Page] Get Location Generic Landing',
    props<{ request: RequestModel }>()
)

export const getLoctionGenericLandingSuccess = createAction(
    '[Widgets Page] Get Location Generic Landing Success',
    props<{ landing: string }>()
)

export const widgetError = createAction(
    '[Widgets Page] Widgets Error',    
    props<{ error: string }>()
)

export const loggerRequest = createAction(
    '[App] Logger Request',
    props<{ request: RequestModel }>()
)

export const loggerRequestSuccess = createAction(
    '[App] Logger Request Success',
    props<{ response: any }>()
)