import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppActions } from '../actions/index';
import { HttpApiService } from '../../services/http-api.service';
import { ReviewModel, ReviewStructuredModel, ReviewNonStructuredModel, RequestModel, WidgetModel, LocationModel } from '../../models';
import * as fromApp from '../reducers/index'
import { ReviewPagesId } from '../../models/reviewPagesId.model';

@Injectable()
export class AppEffects {
    searchReviewsByLocation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.filterReviewsByLocation),
            map(action => action.request),
            switchMap((request) =>
                this.httpApiService.postRequest(request)
                    .pipe(
                        map((data) => data as { data: ReviewModel[], widget: WidgetModel, total: number }),
                        map(data => AppActions.searchWidgetReviewsSuccess({ ...data })),
                        catchError(error => of(AppActions.widgetError({ error: error.message })))
                    )
            )
        )
    );

    getWidgetConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.getWidgetConfig),
            map(action => action.request),
            switchMap(request =>
                this.httpApiService.postRequest(request)
                    .pipe(
                        map((data: {id: string, key: string, widgetDTO: WidgetModel}) => data.widgetDTO),
                        map(widget => AppActions.getWidgetConfigSuccess({widget})),
                        catchError(error => of(AppActions.widgetError({ error: error.message })))
                    )
            )
        )
    );

    getReviewStructuredInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.getReviewStructuredInfo),
            map(action => action.request),
            switchMap((request) =>
                this.httpApiService.postRequest(request)
                    .pipe(
                        map((data: {data: ReviewModel[], total: number}) => data),
                        tap(data=>console.log("response structured",data)),
                        map(review => AppActions.getReviewStructuredInfoSuccess(review)),
                        catchError(error => of(AppActions.widgetError({ error: error.message })))
                    )
            )
        )
    );

    getReviewNonStructuredInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.getReviewNonStructuredInfo),
            map(action => action.request),
            switchMap((request) =>
                this.httpApiService.postRequest(request)
                    .pipe(
                        map((data: {data: ReviewModel[], total: number}) => data),
                        tap(data=>console.log("response non structure",data)),
                        map(review => AppActions.getReviewNonStructuredInfoSuccess(review)),
                        catchError(error => of(AppActions.widgetError({ error: error.message })))
                    )
            )
        )
    );

    getReviewsByUrl$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.getReviewsByUrl),
            map(action => action.request),
            switchMap((request) =>
                this.httpApiService.postRequest(request)
                    .pipe(
                        map((data) => data as { data: ReviewModel[], widget: WidgetModel, total: number }),
                        map(data => AppActions.searchWidgetReviewsSuccess({ ...data })),
                        catchError(error => of(AppActions.widgetError({ error: error.message })))
                    )
            )
        )
    );

    getPagesReviewsId$ = createEffect(() =>
    this.actions$.pipe(
        ofType(AppActions.getPagesId),
        map(action => action.request),
        switchMap((request) =>
            this.httpApiService.postRequest(request)
                .pipe(
                    map((data) => data as ReviewPagesId[]),
                    map(data => AppActions.getPagesIdSuccess({ data })),
                    catchError(error => of(AppActions.widgetError({ error: error.message })))
                )
        )
        )
    );

    getLoctionReviewsCount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.getLoctionReviewsCount),
            map(action => action.request),
            withLatestFrom(
                this.store.select(fromApp.getWidget),
                (request: RequestModel, widget: WidgetModel) => ({
                    ...request,
                    params: request.params + `${widget.locationId}`
                })
            ),
            switchMap((request) =>
                this.httpApiService.getRequest(request)
                    .pipe(
                        map((data: { size: number }) => data.size),
                        map(reviewsCount => AppActions.getLoctionReviewsCountSuccess({ reviewsCount })),
                        catchError(error => of(AppActions.widgetError({ error: error.message })))
                    )
            )
        )
    );

    getLoctionOverallRating$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.getLoctionOverallRating),
            map(action => action.request),
            withLatestFrom(
                this.store.select(fromApp.getWidget),
                (request: RequestModel, widget: WidgetModel) => {
                    return ({
                        ...request,
                        params: request.params + `locationId=${widget.locationId}`
                    })
                }
            ),
            switchMap((request) =>
                this.httpApiService.getRequest(request)
                    .pipe(
                        map((data: number) => data),
                        map(rating => AppActions.getLoctionOverallRatingSuccess({ rating })),
                        catchError(error => of(AppActions.widgetError({ error: error.message })))
                    )
            )
        )
    );

    getLoctionStarsCount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.getLoctionStarsCount),
            map(action => action.request),
            withLatestFrom(
                this.store.select(fromApp.getWidget),
                (request: RequestModel, widget: WidgetModel) => {
                    return ({
                        ...request,
                        params: request.params + `locationId=${widget.locationId}`
                    })
                }
            ),
            switchMap((request) =>
                this.httpApiService.getRequest(request)
                    .pipe(
                        map((data: { data: number[] }) => data.data),
                        map(starsCount => AppActions.getLoctionStarsCountSuccess({ starsCount })),
                        catchError(error => of(AppActions.widgetError({ error: error.message })))
                    )
            )
        )
    );

    getLoctionGenericLanding$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.getLoctionGenericLanding),
            map(action => action.request),
            withLatestFrom(
                this.store.select(fromApp.getWidget),
                (request: RequestModel, widget: WidgetModel) => {
                    return ({
                        ...request,
                        params: request.params + `locationId=${widget.locationId}`
                    })
                }
            ),
            switchMap((request) =>
                this.httpApiService.getRequest(request)
                    .pipe(
                        map((data: { data: string }) => data.data),
                        map(landing => AppActions.getLoctionGenericLandingSuccess({ landing })),
                        catchError(error => of(AppActions.widgetError({ error: error.message })))
                    )
            )
        )
    );

    saveLogger$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.loggerRequest),
            switchMap(({ request }) =>
                this.httpApiService
                    .postRequest(request)
                    .pipe(
                        map(response => AppActions.loggerRequestSuccess({ response })),
                        catchError(error => of(AppActions.widgetError({ error: error.message })))
                    )
            )
        ));

    findLocationById$ = createEffect(() =>
        this.actions$.pipe(
          ofType(AppActions.findLocationById),
          switchMap(({ request }) =>
          this.httpApiService
              .getRequest(request)
              .pipe(
                  map(( data: LocationModel ) => data),
                  map(location => AppActions.findLocationByIdSuccess({ location })),
                  catchError(error => of(AppActions.widgetError({ error: error.message })))
              )
      )
        )
      );

    constructor(
        private actions$: Actions,
        private httpApiService: HttpApiService,
        private store: Store<any>
    ) { }
}