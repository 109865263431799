import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AppFacadeService } from '@services/app-facade.service';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LoggerRequestInterceptor implements HttpInterceptor {
    mapRequest: { key: string, value: any } = { key: "", value: null }

    constructor(
        private appFacadeService: AppFacadeService,
    ) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isVerbose = environment.version.includes("VERBOSE");
        const allowRequest = request.url.includes("maintenance/logger") || (request.url.includes('assets'))

        if (isVerbose && !allowRequest) {
            this.mapRequest[request.url] = request.body == null ? {} : request.body
        }
        return next.handle(request)
            .pipe(
                tap(evt => {
                    if ((evt instanceof HttpResponse) && isVerbose && !allowRequest) {
                        const responseBody = evt.body == null ? {} : evt.body
                        this.appFacadeService.loggerRequest({
                            request: JSON.stringify(this.mapRequest[evt.url]),
                            response: JSON.stringify(responseBody),
                            url: evt.url,
                            version: environment.version
                        })
                    }
                }),
            );
    }
}