import {
  Component,
  AfterContentInit,
  OnDestroy,
  ViewChild,
  NgZone,
  isDevMode,
  HostListener,
  Inject,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Subscription, never, Observable, combineLatest } from 'rxjs';
import { filter, map, tap, withLatestFrom, skip, debounceTime } from 'rxjs/operators';
import { ReviewModel, WidgetModel, GeneralRatingModel, ReviewStructuredModel, ReviewNonStructuredModel, ReviewRequestFilteredPaginatedModel } from '../../../core/models';
import { emptyFilterReviewModel } from '../../../core/utilities/data-utils'
import { AppFacadeService } from '../../../core/services/app-facade.service';
import { WidgetDisplayMode } from '../../../core/enums/widgetDisplayMode.enum';
import { WidgetNameDisplay } from '../../../core/enums/widgetNameDisplay.enum';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';

@Component({
  selector: 'widget',
  templateUrl: './widget.component.html', 
  styleUrls: ['./widget.component.sass']
})

export class WidgetComponent implements AfterContentInit, OnDestroy {
  @ViewChild('pagination') paginator: any
  paginatorDisabled: boolean = false;
  showCarrousel: boolean = false
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    autoHeight: true,
    navSpeed: 700,
    navText: ['', ''],
    autoplay: true,
    center: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false
  }

  _subs: Subscription = never().subscribe()

  widgetDisplayMode = WidgetDisplayMode;
  widgetNameDisplay = WidgetNameDisplay;

  reviews$: Observable<ReviewModel[]> = this.appFacadeService.getWidgetReviewsByLocation$();

  collectionSize$ = this.appFacadeService.getWidgetReviewsTotal$();
  collectionSize: number
  // widget$ = this.appFacadeService.getWidgetByLocation$();
  widget$ = this.appFacadeService.getWidgetConfig$();
  getReviewsCombined$ = this.appFacadeService.getReviewsCombined$()
  getReviewsCombined: ReviewModel[]
  reviewNonStructured$ = this.appFacadeService.getReviewNonStructured$()
  reviewsCount$ = this.appFacadeService.getLoctionReviewsCount$();
  rating$ = this.appFacadeService.getLoctionOverallRating$();
  starsCount$ = this.appFacadeService.getLoctionStarsCount$();
  landing$ = this.appFacadeService.getLoctionGenericLanding$();

  reviewsJoint: any[] = []

  // a short-term workaround to ensure that, when the Carousel is used, the first visible review will be
  // tall enough to calculate our iframe height. See `w.unmin.js` or `app.component.ts` for more details.
  //
  // we also limit review height via CSS so no more than 3-4 lines will be shown, for consistency / UX.
  //
  // this property is only used if: (widget$ | async)?.displayMode == widgetDisplayMode.Carousel
  reviewsWithLongestFirst: any[] = []
  
  widget: WidgetModel = {
    nameDisplay: 'long',
    bgColor: '#F5F5F5',
    numberOfReviews: 30,
    reviewsPerPage: 10,
    displayMode: 'stack',
    filterByRating: [],
    filterByReviewSites: [],
    onlyReviewsWithNoText: false
  };

  generalRating: GeneralRatingModel = {
    readOnly: "true",
    rating: "0.0",
    ratingColor: "",
    fillColor: "#ffb200",
    graphLinesColor: "#ffb200",
    progressBarLabelsColor: "",
    progressBarPercentageValues: [0, 0, 0, 0, 0],
    countingPerStars: [0, 0, 0, 0, 0]
  };
  reviewsCount: number = 0;
  landing: string = '';

  locationId: string = '';
  page = 0;
  startFrom: string = "";
  datePosted: number;
  selectedPage: number = 1
  lastSelectedPage: number = 1;
  pagesId$ = this.appFacadeService.getPagesId$();
  pagesId: {page: number, startKey: string, startDatePosted: number}[] = [{page: 1, startKey: null, startDatePosted: null}];
  reviewRequestFiltered: ReviewRequestFilteredPaginatedModel

  constructor(
    private appFacadeService: AppFacadeService,
    private route: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  ngOnInit() { }

  ngAfterContentInit() {
    // this.appFacadeService.getReviewsByUrl({url: this.href(), page: 0})
    this.appFacadeService.getWidgetConfig({ key: this.href(), page: 0 })
    
    this._subs.add(
      this.widget$
      .pipe(
        filter(widget => widget.locationId ? true : false)
        ).subscribe(widget => {
          // TODO THIS OPERATION WE CAN TO DO  THIS REQUEST IN THE EFFECT WHEN THE DATA ARRIVE WITH MERGEMAP[]
          this.widget = widget;
          this.appFacadeService.getReviewStructuredInfo({ startKey: null, startDatePosted: null, widget: widget, key: this.href()})
          this.appFacadeService.getReviewNonStructuredInfo({ startKey: null, startDatePosted: null, widget: widget, key: this.href() })
          this.appFacadeService.getLoctionReviewsCount();
          this.appFacadeService.getLoctionOverallRating();
          this.appFacadeService.getLoctionGenericLanding();
          this.appFacadeService.getLoctionStarsCount();
          this.appFacadeService.findLocationById(widget.locationId)

          this.document.body.style.backgroundColor = widget.bgColor;
        })
    );

    this._subs.add(
      this.reviewsCount$
        .subscribe(reviewsCount => {
          this.reviewsCount = reviewsCount;
        })
    );

    this._subs.add(
      this.appFacadeService.getLocation$()
      .pipe(skip(1))
      .subscribe(location => {
        console.log(location)
        location ? 
          !location.id ? this.router.navigate(['']) : ''
          : this.router.navigate([''])
      })
    )

    this._subs.add(
      this.getReviewsCombined$
        .subscribe(reviewsCombined => {
          this.getReviewsCombined = reviewsCombined;
          console.log('reviews combined', reviewsCombined)
          reviewsCombined.length ? this.searchIds() : ''
          

          if (!reviewsCombined || reviewsCombined.length < 1) return;
          this.reviewsWithLongestFirst = longestFirst(reviewsCombined);
          // TODO: unused now?
          this.reviewsJoint = reviewsCombined;

          // notify the parent: data has loaded ("onNextTick" / after setTimeout, so the UI has loaded)
          // so that the parent can resize the iframe based on the this component's new height
          const tryNotify = () => {
            const w: Window | null = this.document.defaultView || null;

            if (w) try {
              const scrollHeight = this.document.body.scrollHeight;
              // if the scrollHeight isn't set yet, wait another second for render
              if (scrollHeight == 0) return setTimeout(tryNotify, 1000);

              // send a colon-separated string instead of an object for best cross-browser support (i.e. Internet Explorer)
              w.parent.postMessage('didFetchData:' + this.document.body.scrollHeight, '*')
            } catch (err) {
              if (isDevMode()) {
                console.error(err);
                console.warn('unable to postMessage to parent window');
              }
            }
          }
          setTimeout(tryNotify, 8);
        })
    );

    this._subs.add(
      this.pagesId$
      .subscribe(pagesId => {
        this.paginatorDisabled = false;
        const previousPages = this.pagesId
        if(this.paginator){
          const calcStartPage = this.calcPagesInterval(this.paginator.pages, this.selectedPage, this.lastSelectedPage).pageStart
          this.pagesId = pagesId
          if(calcStartPage > 1) {
            this.pagesId.unshift(Object.assign({}, {page: calcStartPage, startKey: previousPages.find(pageId => pageId.page === calcStartPage).startKey, startDatePosted: previousPages.find(pageId => pageId.page === calcStartPage).startDatePosted}))
          }
          this.pagesId.unshift(Object.assign({}, {page: 1, startKey: null, startDatePosted: null}))
        }
      }
      )
    )

    this._subs.add(
      this.collectionSize$.subscribe(collectionSize => {
        this.collectionSize = collectionSize
        console.log('collectionSize', this.collectionSize)
      }
      )
    )

    this._subs.add(
      this.rating$
        .subscribe(rating => {
          this.generalRating.rating = rating.toFixed(1).toString();
        })
    );

    this._subs.add(
      this.starsCount$
        .pipe(
          filter(starsCount => starsCount.length > 0)
        )
        .subscribe(starsCount => {
          const starTotal = starsCount.reduce((acc, count) => acc += count, 0)
          this.generalRating.countingPerStars = starsCount;
          this.generalRating.progressBarPercentageValues = starTotal ? starsCount.map((data) => data * 100 / starTotal) : [0, 0, 0, 0, 0]
        })
    );
  }

  href(): string {
    return window.location.href;
  }

  onPageChange(page: number) {
    this.lastSelectedPage = this.selectedPage;
    this.selectedPage = page
    const pages: number[] = this.paginator.pages;
    this.startFrom = this.pagesId.length > 0 ? this.pagesId.find(pageId => pageId.page === page).startKey : null
    this.datePosted = this.pagesId.length > 0 ? this.pagesId.find(pageId => pageId.page === page).startDatePosted : null
    this.appFacadeService.getReviewStructuredInfo({ startKey: this.startFrom, startDatePosted: this.datePosted, widget: this.widget, key: this.href() })
    this.appFacadeService.getReviewNonStructuredInfo({ startKey: this.startFrom, startDatePosted: this.datePosted, widget: this.widget, key: this.href() })
  }

  searchIds() {
    setTimeout(() => { this.ngZone.run(() => {
      this.paginatorDisabled =true;
      const pages: number[] = this.paginator ? this.paginator.pages : []
      const {pageStart, pageEnd} = this.calcPagesInterval(pages, this.selectedPage, this.lastSelectedPage)
      setTimeout((() =>{
        this.reviewRequestFiltered = { ...this.reviewRequestFiltered, 
          id: this.widget.locationId,
          pageStartId: this.pagesId.find(pageId => pageId.page === pageStart) ? this.pagesId.find(pageId => pageId.page === pageStart).startKey : null,
          pageStartDatePosted: this.pagesId.find(pageId => pageId.page === pageStart) ? this.pagesId.find(pageId => pageId.page === pageStart).startDatePosted : null,
          pageSize: this.widget.reviewsPerPage,
          pageStart: pageStart,
          pageEnd: pageEnd,
          lastPage: pages[pages.length-1],
          getOposedExtremeId: true,
          filterWidget: this.widget,
          filterReview: emptyFilterReviewModel(),
          locationIdsList: []
        }
        this.appFacadeService.searchPagesId({reviewRequestFiltered: this.reviewRequestFiltered})
        }),1000)
    }) }, 100);
  }

  calcPagesInterval(pagesArray:number[], selectedPage: number, lastSelectedPage: number):{pageStart: number, pageEnd: number}{
    if(pagesArray.filter(item => item == -1).length == 0){
      return {pageStart: pagesArray[0], pageEnd: pagesArray[pagesArray.length -1 ]}
    }
    else {
      if(pagesArray.filter(item => item == -1).length == 1 && pagesArray.findIndex(item => item == -1) == pagesArray.length - 2){
        return {pageStart: pagesArray[0], pageEnd: pagesArray[pagesArray.findIndex(item => item == -1) -1 ]}
      }
      if(pagesArray.filter(item => item == -1).length == 2 && selectedPage > lastSelectedPage){
        return {pageStart: pagesArray[pagesArray.findIndex(item => item == -1) + 1 ], pageEnd: pagesArray[pagesArray.lastIndexOf(-1) -1]}
      }
      if(pagesArray.filter(item => item == -1).length == 2 && selectedPage < lastSelectedPage){
        return {pageStart: pagesArray[pagesArray.lastIndexOf(-1) -1], pageEnd: pagesArray[pagesArray.findIndex(item => item == -1) + 1 ]}
      }
      if(pagesArray.filter(item => item == -1).length == 1 && pagesArray.findIndex(item => item == -1) == 1){
        return {pageStart: pagesArray[pagesArray.length -1], pageEnd: pagesArray[pagesArray.findIndex(item => item == -1) + 1 ]}
      }
    }
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe()
  }

  // In the event that the parent (w.unmin.js) determines it should NOT resize the iframe, it will respond
  // to our postMessage to indicate that we should make our body scrollable.
  @HostListener('window:message', ['$event']) onPostMessage(event: Event & { data: any }) {
    // we do not know the origin of the event because of the nature of embed codes (could be any domain).
    // be VERY careful in how the event is parsed/manipulated if further changes are made here.
    if (event.data === 'parentNeedsScrolling') {
      this.document.body.style.overflow = 'scroll';
      this.document.body.className = 'scrolling';
    }
  }
}

// takes an array of objects that have a `message` property and returns the same array except the
// object with the longest message will be the first element in the array (the rest is unchanged)
function longestFirst<T>(arr: (T & { message?: string })[]): (T & { message?: string })[] {
  // get the longest review's key/value pair
  const { key: index, value } = Object.keys(arr)
    .map(key => parseInt(key, 10)) // keys() returns string keys even for arrays; convert to Number
    .map(key => {
      // make sure the review message is non-null, so we can compare lengths
      return { key, value: { ...arr[key], message: arr[key].message || '' } };
    })
    // DESC / descending
    .sort((lhs, rhs) => rhs.value.message.length - lhs.value.message.length)
    .shift();

  // copy the array + move the longest review to the front.
  const output = arr.slice();
  output.splice(index, 1);
  output.unshift(value);

  return output;
}
