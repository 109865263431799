import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppActions } from '../../core/store/actions/index'
import * as fromWidgets from '../store/reducers/index'
import { ReviewModel, ReviewStructuredModel, ReviewNonStructuredModel, RequestModel, WidgetModel, LocationModel, ReviewRequestFilteredPaginatedModel } from '../models/index';

@Injectable({
    providedIn: 'root'
})
export class AppFacadeService {

    constructor(private store: Store<any>, public router: Router,) { }

    getWidgetReviewsByLocation$(): Observable<ReviewModel[]> {
        return this.store.select(fromWidgets.getWidgetReviews)
    }

    getPagesId$(): Observable<{page: number, startKey: string, startDatePosted: number}[]> {
        return this.store.select(fromWidgets.getPagesId)
    }

    getLocation$(): Observable<LocationModel> {
        return this.store.select(fromWidgets.getLocation)
    }

    getWidgetConfig$(): Observable<WidgetModel> {
        return this.store.select(fromWidgets.getWidget)
    }

    getReviewStructured$(): Observable<ReviewModel[]> {
        return this.store.select(fromWidgets.getReviewStructured)
    }

    getReviewNonStructured$(): Observable<ReviewModel[]> {
        return this.store.select(fromWidgets.getReviewNonStructured)
    }

    getWidgetByLocation$(): Observable<WidgetModel> {
        return this.store.select(fromWidgets.getWidget)
    }

    getWidgetReviewsTotal$(): Observable<number> {
        return this.store.select(fromWidgets.getWidgetReviewsTotal)
    }

    getLoctionReviewsCount$(): Observable<number> {
        return this.store.select(fromWidgets.getWidgetsLoctionReviewsCount)
    }

    getLoctionOverallRating$(): Observable<number> {
        return this.store.select(fromWidgets.getWidgetsLoctionOverallRating)
    }

    getLoctionGenericLanding$(): Observable<string> {
        return this.store.select(fromWidgets.getLoctionGenericLanding)
    }

    getLoctionStarsCount$(): Observable<number[]> {
        return this.store.select(fromWidgets.getLoctionStarsCount)
    }

    getWidgetsError$(): Observable<string> {
        return this.store.select(fromWidgets.getErrorWidgets)
    }

    filterWidgetReviewsByLocation(payload: { page: number, widget: WidgetModel, locationId: string }) {
        const request: RequestModel = {
            params: `widgets?locationId=${payload.locationId}&page=${payload.page}`,
            payload: payload.widget
        }
        this.store.dispatch(AppActions.filterReviewsByLocation({ request }))
    }

    searchPagesId(payload: { reviewRequestFiltered: ReviewRequestFilteredPaginatedModel }) {
        const request: RequestModel = {
            params: `widgets/review-ids-paginated`,
            payload: payload.reviewRequestFiltered
        }
        this.store.dispatch(AppActions.getPagesId({ request }))
    }

    getWidgetConfig(payload: {key: string, page: number}) {
        const request: RequestModel = {
            params: `widgets/config?key=${payload.key}&page=${payload.page}`,
            payload: { key: payload.key, page: payload.page }
        }
        this.store.dispatch(AppActions.getWidgetConfig({ request }))
    }

    getReviewStructuredInfo(payload: {startKey: string, startDatePosted: number, widget: WidgetModel, key: string}) {
        const request: RequestModel = {
            params: `widgets/filter-structured?lastKey=${payload.startKey}&datePosted=${payload.startDatePosted}`,
            payload: {key: payload.key, page: 0}
        }
        this.store.dispatch(AppActions.getReviewStructuredInfo({ request }))
    }

    getReviewNonStructuredInfo(payload: {startKey: string, startDatePosted: number, widget: WidgetModel, key: string}) {
        const request: RequestModel = {
            params: `widgets/filter-no-structured?lastKey=${payload.startKey}&datePosted=${payload.startDatePosted}`,
            payload: {key: payload.key, page: 0}
        }
        this.store.dispatch(AppActions.getReviewNonStructuredInfo({ request }))
    }

    getReviewsByUrl(payload: { url: string, page: number }) {
        const request: RequestModel = {
            params: `widgets/filter`,
            payload: { key: payload.url, page: payload.page }
        }
        this.store.dispatch(AppActions.getReviewsByUrl({ request }))
    }

    getLoctionReviewsCount() {
        const request: RequestModel = {
            params: `business-report/location/review-count/`
        }
        this.store.dispatch(AppActions.getLoctionReviewsCount({ request }))
    }

    findLocationById(locationId: string) {
        const request: RequestModel = {
            params: `locations/${locationId}`
        }
        this.store.dispatch(AppActions.findLocationById({ request }))
    }

    getLoctionStarsCount() {
        const request: RequestModel = {
            params: `reviews/star-count-location?`
        }
        this.store.dispatch(AppActions.getLoctionStarsCount({ request }))
    }

    getLoctionOverallRating() {
        const request: RequestModel = {
            params: `reviews/average-rating-location?`
        }
        this.store.dispatch(AppActions.getLoctionOverallRating({ request }))
    }

    getLoctionGenericLanding() {
        const request: RequestModel = {
            params: `landing/generic-landing-url?`
        }
        this.store.dispatch(AppActions.getLoctionGenericLanding({ request }))
    }
    loggerRequest(payload: any) {
        const request: RequestModel = {
            params: `maintenance/logger`,
            payload
        }
        this.store.dispatch(AppActions.loggerRequest({ request }))
    }

    getReviewsCombined$(): Observable<ReviewModel[]> {
        return this.store.select(fromWidgets.getReviewsCombined)
    }
}
