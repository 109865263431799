import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';

@Pipe({
  name: 'getReviewData'
})
export class GetReviewDataPipe implements PipeTransform, OnDestroy {
  private data: any = null;
  _subs: Subscription = new Subscription()

  constructor(private http: HttpClient) { }
  transform(url: string): Observable<any> {
    return this.http.get(url, { responseType: "text" })
  }
  ngOnDestroy(): void {
    this._subs.unsubscribe()
  }

}
