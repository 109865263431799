import { ReviewModel } from "../models";

export const mapReviewStructured = (structured: ReviewModel, nonStructured: ReviewModel): ReviewModel => {
    return {
        ...structured,
        reviewSiteIcon: nonStructured.reviewSiteIcon,
        reviewResponses: nonStructured.reviewResponses
    }
}

export const combineReviewStrutured = (structureds: ReviewModel[], nonStructureds: ReviewModel[]): ReviewModel[] => {

    console.log("fnc structured", structureds)
    console.log("fnc non structured",  nonStructureds)

    const result = structureds.map(item1 => nonStructureds
        .filter(item2 =>{
            if(item1.reviewShakeId != '') return item1.reviewShakeId == item2.reviewShakeId
            else if (item1.googleReviewId != '') return item1.googleReviewId == item2.googleReviewId
            else if (item1.reviewExternId != '') return item1.reviewExternId == item2.reviewExternId
            else return item1.reviewShakeId ==  item2.reviewShakeId
        }
        )
        .map(item2 => mapReviewStructured(item1, item2))
    )
    console.log('result', result)
    return [].concat(...result)
}
