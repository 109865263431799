import { Component, OnInit, Input} from '@angular/core';
import { WidgetNameDisplay } from '../core/enums/widgetNameDisplay.enum';

@Component({
  selector: 'incoming-review',
  templateUrl: './incoming-review.component.html',
  styleUrls: ['./incoming-review.component.sass']
})

export class IncomingReviewComponent implements OnInit {
  currentRate = 3;
  @Input() name : string;
  @Input() lastName: string;
  @Input() reviewUrl: string;
  @Input() photo : string;
  @Input() starPercentage : number;
  @Input() reviewText : string;
  @Input() created : number;
  @Input() siteIcon: string;
  @Input() siteName: string;
  @Input() isFeedback: boolean;
  @Input() displayNameMode: string;
  @Input() truncateReview: boolean;

  showMore = false
  showLetter = false
  widgetNameDisplay = WidgetNameDisplay;

  // The 'truncateReview' feature of this component needs to fallback from an ellipsis to a blur on IE.
  // See `incoming-review.component.ts` file for the associated workaround.
  isIE = !!((window as any).ActiveXObject) /* IE 7-10 */ || "ActiveXObject" in window /* IE 11 */

  constructor() { }

  ngOnInit() { }

  error(){
    this.showLetter = true
  }
}
