import { DateRangeModel } from '../models/dateRange.model'
import { FilterReviewModel } from '../models/filterReview.model'

export const emptyDateRangeModel = (): DateRangeModel => {
    return {
        startDate: null,
        endDate: null
    }
}

export const emptyFilterReviewModel = (): FilterReviewModel => {
    return {
        filterByText: null,
        filterByDate: emptyDateRangeModel(),
        filterByRating: null,
        filterByReviewSites: null,
        filterByMessage: null
    }
}