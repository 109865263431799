import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GetReviewDataPipe } from './get-review-data.pipe';

const PIPES = [
  GetReviewDataPipe
];


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ...PIPES,
  ]
  , exports: [
    ...PIPES
  ]
})
export class PipesModule { }

