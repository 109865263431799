import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule, } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HttpClientModule } from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ShortNumberPipe } from './short-number.pipe';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { PipesModule } from './core/pipes/pipes.module';


import { WidgetComponent } from './widgets-app/components/widget/widget.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component'
import { IncomingReviewComponent } from './incoming-review/incoming-review.component'
import { GeneralRatingComponent } from './general-rating/general-rating.component'

@NgModule({
  declarations: [
    AppComponent,
    UnauthorizedComponent,
    WidgetComponent,
    IncomingReviewComponent,
    GeneralRatingComponent,
    ShortNumberPipe
  ],
  imports: [
    AppRoutingModule,
    CoreModule,
    CommonModule,
    CarouselModule,
    HttpClientModule,
    NgbModule,
    BrowserAnimationsModule,
    BrowserModule,
    MatSnackBarModule,
    PipesModule
  ],
  providers: [
  ],
  exports: [
    ShortNumberPipe,
    PipesModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }

