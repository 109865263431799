import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AppFacadeService } from '../services/app-facade.service';
import { finalize, tap, catchError } from 'rxjs/operators';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root'
})
export class HeaderInterceptor implements HttpInterceptor {
    constructor(
        private appFacadeService: AppFacadeService,
        private _snackBar: MatSnackBar
    ) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({
            headers: request.headers
                .append('Content-Type', 'application/json')
                .append('version', environment.version)
                .append('typeApp', environment.typeApp)
        });
        if (!(request.url.includes('users/signup'))) {
            request = request.clone({
                headers: request.headers,
            });
        }
        return next.handle(request)
            .pipe(
                catchError((dataError: HttpErrorResponse) => {
                    const data = {
                        reason: dataError && dataError.error.reason ? dataError.error.reason : '',
                        status: dataError.status,
                        message: dataError && dataError.error.message ? dataError.error.message : dataError.message,
                        requestId: dataError && dataError.error.requestId ? dataError.error.requestId : dataError.statusText,
                    };
                    return throwError(dataError);
                })
            );
    }
}
