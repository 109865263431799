import {
  AfterContentInit,
  Component,
} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements AfterContentInit {

  constructor(
  ) { 
  }

  ngOnInit() {
  }

  ngAfterContentInit() {
  }

}
