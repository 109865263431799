import {Component, OnInit, Input, AfterContentInit} from '@angular/core';

@Component({
  selector: 'general-rating',
  templateUrl: './general-rating.component.html',
  styleUrls: ['./general-rating.component.sass']
})
export class GeneralRatingComponent implements OnInit, AfterContentInit {

  @Input() readOnly: boolean;
  @Input() rating: number;
  @Input() ratingColor: string;
  @Input() fillColor: string;
  @Input() bgColor: string;
  @Input() graphLinesColor: string;
  @Input() progressBarLabelsColor: string;
  @Input() progressBarPercentageValues: number[];
  @Input() countingPerStars: number[];
  @Input() reviewsCount: number;
  
  constructor() { 
  }
  
  ngOnInit() { 
  }
  
  ngAfterContentInit(){
  }

}
