import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { WidgetComponent } from './widgets-app/components/widget/widget.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component'

const routes: Routes = [
  { path: ':shortId', component: WidgetComponent },
  { path: '**', component: UnauthorizedComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
