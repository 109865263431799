import { createReducer, on } from '@ngrx/store';
import { AppActions } from '../actions/index'
import { ReviewModel, ReviewStructuredModel, ReviewNonStructuredModel, WidgetModel, LocationModel } from '../../models/index'

export interface State {
    widget: WidgetModel;
    location: LocationModel;
    reviewStructured:  ReviewModel [];
    reviewNonStructured:  ReviewModel [];
    reviews: ReviewModel [];
    total: number;
    reviewsCount: number;
    rating: number;
    starsCount: number[];
    landing: string;
    error: string;
    pagesId: {page: number, startKey: string, startDatePosted: number}[],

}

const initialState: State = {
    widget: {
        filterByRating: [],
        filterByReviewSites: [],
        onlyReviewsWithNoText: false,
        nameDisplay: 'fullName',
        bgColor: '#f5f5f5',
        numberOfReviews: 30,
        reviewsPerPage: 10,
        displayMode: 'stack'
    },
    location: {
        name: '',
        archived: false,
        phoneNumber: null,
        zipCode: null,
        maxRequestQuota: 50,
        address: null,
        parentBusiness: null,
        id: null,
        shortName: '',
        countryCode: ''
    },
    reviewStructured: [],
    reviewNonStructured: [],
    reviews: [],
    total: 0,
    reviewsCount: 0,
    rating: 0,
    starsCount: [],
    landing: '',
    error: '',
    pagesId: [],
};

export const reducer = createReducer(
    initialState,
    on(AppActions.searchWidgetReviewsSuccess, (state, { data, widget, total }) => ({
        ...state,
        reviews: data,
        widget,
        total,
    })),
    on(AppActions.getWidgetConfigSuccess, (state, {widget}) => ({ ...state, widget: widget})),
    on(AppActions.findLocationByIdSuccess, (state, {location}) => ({ ...state, location: location })),
    on(AppActions.getReviewStructuredInfoSuccess, (state, {data, total}) => ({ ...state, reviewStructured: data, total})),
    on(AppActions.getPagesIdSuccess, (state, { data }) => ({
        ...state,
        pagesId: data
    })),
    on(AppActions.getReviewNonStructuredInfoSuccess, (state, {data, total}) => ({ ...state, reviewNonStructured: data})),
    on(AppActions.getLoctionReviewsCountSuccess, (state, {reviewsCount}) => ({ ...state,reviewsCount})),
    on(AppActions.getLoctionOverallRatingSuccess, (state, {rating}) => ({ ...state,rating})),
    on(AppActions.getLoctionStarsCountSuccess, (state, {starsCount}) => ({ ...state,starsCount})),
    on(AppActions.getLoctionGenericLandingSuccess, (state, {landing}) => ({ ...state,landing})),
    on(AppActions.widgetError, (state, { error }) => ({ ...state, error: error })),
);

export const getReviews = (state: State) => state.reviews;
export const getPagesId = (state: State) => state.pagesId
export const getLocation = (state: State) => state.location;
export const getWidget = (state: State) => state.widget;
export const getReviewStructured = (state: State) => state.reviewStructured
export const getReviewNonStructured = (state: State) => state.reviewNonStructured
export const getTotal = (state: State) => state.total;
export const getReviewsCount = (state: State) => state.reviewsCount;
export const getRating = (state: State) => state.rating;
export const getStarsCount = (state: State) => state.starsCount;
export const getLanding = (state: State) => state.landing;
export const getError = (state: State) => state.error;
