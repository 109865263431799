import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { RequestModel } from '../models/index';

@Injectable({
    providedIn: 'root'
})
export class HttpApiService {
    constructor(
        private http: HttpClient
    ) { }

    getRequest(data: RequestModel): Observable<Object> {
        return this.http
            .get(`${environment.apiPath}/${data.params}`)
    }

    postRequest(data: RequestModel): Observable<Object> {
        return this.http
            .post(`${environment.apiPath}/${data.params}`, data.payload)
    }
    
}