import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppEffects } from '../core/store/effects/app.effects';

import { ROOT_REDUCERS, metaReducers } from './store/reducers'
import { HeaderInterceptor } from './interceptors/header.interceptor';
import { LoggerRequestInterceptor } from './interceptors/logger-request.interceptor';


@NgModule({
  declarations: [
  ],
  imports: [
    BrowserModule,
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
      },
    }),
    EffectsModule.forRoot([
      AppEffects
    ])
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoggerRequestInterceptor,
      multi: true
    },
  ]
})
export class CoreModule { }
