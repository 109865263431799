import { InjectionToken } from '@angular/core';
import {
  createSelector,
  createFeatureSelector,
  ActionReducer,
  Action,
  ActionReducerMap,
  MetaReducer
} from '@ngrx/store';
import { combineReviewStrutured } from '../../utilities/function-utils';

import * as app from '../reducers/app.reducer'

export interface State {
  app: app.State
}

export const ROOT_REDUCERS = new InjectionToken<
  ActionReducerMap<State, Action>
>('Root reducers token', {
  factory: () => ({
    app: app.reducer
  }),
});

export const metaReducers: Array<MetaReducer<any, any>> = [];


/**
 * Reviews Reducers
 */


export const getWidgetsState = createFeatureSelector<State, app.State>(
  'app'
);

export const getWidgetReviews = createSelector(
  getWidgetsState,
  app.getReviews
)

export const getPagesId = createSelector(
  getWidgetsState,
  app.getPagesId
)

export const getLocation = createSelector(
  getWidgetsState,
  app.getLocation
)

export const getWidget = createSelector(
  getWidgetsState,
  app.getWidget
)

export const getReviewStructured = createSelector(
  getWidgetsState,
  app.getReviewStructured
)

export const getReviewNonStructured = createSelector(
  getWidgetsState,
  app.getReviewNonStructured
)

export const getWidgetReviewsTotal = createSelector(
  getWidgetsState,
  app.getTotal
)

export const getWidgetsLoctionReviewsCount = createSelector(
  getWidgetsState,
  app.getReviewsCount
)

export const getWidgetsLoctionOverallRating = createSelector(
  getWidgetsState,
  app.getRating
)

export const getLoctionStarsCount = createSelector(
  getWidgetsState,
  app.getStarsCount
)

export const getLoctionGenericLanding = createSelector(
  getWidgetsState,
  app.getLanding
)

export const getErrorWidgets = createSelector(
  getWidgetsState,
  app.getError
);

export const getReviewsCombined = createSelector(
  getReviewStructured,
  getReviewNonStructured,
  (structured, nonStructured) => combineReviewStrutured(structured, nonStructured)
)
